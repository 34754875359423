import {Button, Dropdown, Icon, Input, Loader, Modal} from "semantic-ui-react";
import React, {ChangeEvent, SyntheticEvent} from "react";
import _ from 'lodash'
import moment from "moment";
import firebase from "firebase/compat";
import {Request} from "./Request";
import {payment_types} from "../system/app/payments/AddPayment";

import cash_icon from "../../assets/images/payment/cash_icon.jpg";
import mtn_mm from "../../assets/images/payment/mtn_mm.jpg";
import airtel_mm from "../../assets/images/payment/airtel_mm.jpg";
import dtb from "../../assets/images/payment/logo_dtb.jpeg";
import pesa from "../../assets/images/payment/pesapal.png";
import credit from "../../assets/images/payment/credit_card.jpg";
import debit from "../../assets/images/payment/debit_card.png";
import booking from "../../assets/images/payment/booking.png";

import pharma_logo from "../../assets/images/logos/tena.png"
import medipal_logo from "../../assets/images/logos/medipal.png"
import medsafe_logo from "../../assets/images/logos/medsafe.png"
import melabs_logo from "../../assets/images/logos/melabs.png"
import times_logo from "../../assets/images/logos/times.jpeg"

import Webcam from "react-webcam";


export interface Sortable {
    column: string | null
    data: Array<any>
    direction: 'ascending' | 'descending' | undefined
}

export interface MyConfirmAction {
    open: boolean
    cancelButton: string
    confirmButton: string
    content: string
    onConfirm?: (event?: SyntheticEvent, data?: object) => void
    onCancel?: (event?: SyntheticEvent, data?: object) => void
}

export const my_initial_confirm: MyConfirmAction = {cancelButton: "Cancel", confirmButton: "Proceed", content: "", open: false}

export interface Fetch {
    start: number
    limit: number
    total: number
    page: number
    pages: number
    load?: boolean
}

export class InputFile {
    file_src: string;
    file: File | undefined;

    constructor(file_path: string, file?: File) {
        this.file_src = file_path;
        this.file = file
    }
}

export interface PaymentMethod {
    amount: string
    show: boolean
    payment_type: payment_types
}

export interface PaymentType {
    payment_type: payment_types
    ref_no: string
    account_name: string
    amount_paid: number
}

export function ShowPDF(params: { show: boolean, close: () => void, url: string }) {
    return (
        <Modal size='large' open={params.show} centered={false} closeOnDimmerClick={false} onClose={params.close}>
            <div className="test_slip">
                {params.url !== "" && <iframe src={params.url} style={{width: '100%', height: '100%'}} frameBorder="0"/>}
            </div>
            <div className="row mx-0">
                <div className="col-12 col-md-4 offset-md-4 p-2">
                    <Button primary size='mini' icon='close' labelPosition="left" content="Close" fluid onClick={params.close}/>
                </div>
            </div>
        </Modal>
    )
}

export function Loading(props: { show: boolean, text: string, hide: () => void }) {
    return (
        <Modal size='small' basic closeOnEscape={false} closeOnDimmerClick={false} open={props.show} onClose={props.hide}>
            <Loader active inline='centered'>{props.text}</Loader>
        </Modal>)
}

export function MyInput(
    props: {
        change?: (name: string, value: string) => void, placeholder: string, name: string, value: string,
        disabled?: boolean, style?: React.CSSProperties, type?: string, enter?: () => void, className?: string
    }) {
    return (
        <>
            <Input placeholder={props.placeholder} fluid size="mini" name={props.name} value={props.value}
                   disabled={props.disabled !== undefined && props.disabled}
                   style={props.style} type={props.type} className={props.className}

                   onChange={((event, data) => {
                       if (props.change !== undefined) {
                           props.change(event.target.name, data.value)
                       }

                   })}
                   onKeyDown={(event: KeyboardEvent) => {
                       if (event.key === 'Enter' && props.enter !== undefined) {
                           props.enter()
                       }
                   }}

            />
        </>
    )
}

export type select_type = string | number | boolean | (string | number | boolean)[] | undefined

export interface SelectData {
    text: string
    value: string | number
}

export function MySelect(
    props: {
        change?: (value: select_type) => void, name: string, value: select_type, placeholder: string, disabled?: boolean,
        options: Array<{ text: string, value: string | number }>, style?: React.CSSProperties, multiple?: boolean
    }) {
    return (
        <>
            <Dropdown size="mini" fluid selection placeholder={props.placeholder} value={props.value}
                      options={props.options} style={props.style} search={true} multiple={props.multiple ? props.multiple : false}
                      disabled={props.disabled ? props.disabled : false}
                      onChange={((event, data) => {
                          if (props.change) {
                              props.change(data.value)
                          }
                      })}/>
        </>
    )
}

export function MyDateInput(
    props: {
        minDate?: string, maxDate?: string, value: string, name: string, placeholder: string,
        change: (name: string, value: string) => void, disabled?: boolean
    }
) {
    return (
        <>
            <input className="date_input" name={props.name} placeholder={props.placeholder} value={props.value}
                   type="date" max={props.maxDate} min={props.minDate} disabled={props.disabled ? props.disabled : false}
                   onChange={(event) => props.change(event.target.name, event.target.value)}/>
        </>
    )
}

export function MyTimeInput(props: { value: string, name: string, placeholder: string, change: (name: string, value: string) => void }) {
    return (
        <>
            <input className="date_input" name={props.name} placeholder={props.placeholder} value={props.value} type="time"
                   max="23:59" min={moment().format("HH:mm")}
                   onChange={(event) => props.change(event.target.name, event.target.value)}/>
        </>
    )
}

export interface MessageProps {
    type: 'success' | 'error' | 'info'
    active: boolean
    message: string
}

export function MyMessage(props: { message: string, type: 'success' | 'error' | 'info', close: () => void, active: boolean }) {
    const [color, setColor] = React.useState<'blue' | 'red' | 'green'>('blue')
    const [icon, setIcon] = React.useState<'checkmark' | 'info' | 'cancel'>('info')

    const get_color = () => {
        if (props.type === 'success') {
            return 'green'
        } else if (props.type === 'error') {
            return 'red'
        }
        return 'blue'
    }

    const get_icon = () => {
        if (props.type === 'success') {
            return 'checkmark'
        } else if (props.type === 'error') {
            return 'cancel'
        }
        return 'info'
    }

    React.useEffect(() => {
        if (props.type === 'success') {
            window.setTimeout(() => props.close(), 2500)
        }
    }, [props.type])

    React.useEffect(() => {
        setColor(get_color)
        setIcon(get_icon)
    }, [])

    React.useEffect(() => {
        setColor(get_color)
        setIcon(get_icon)
    }, [props.type])

    return (
        <Modal size='mini' open={props.active} onClose={props.close} dimmer="blurring" centered={false}>
            <div className="dialog_container">
                <div className="dialog_icon">
                    <Icon name={icon} color={color} size='small'/>
                </div>
                <div className="dialog_message" dangerouslySetInnerHTML={{__html: `${props.message}`}}/>

                <div className="mt-2 row mx-0">
                    <div className="col-8 offset-2">
                        <Button negative icon="close" labelPosition="left" size="tiny" fluid
                                content="Close Window" onClick={props.close}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export interface ConfirmAction {
    open: boolean
    cancelButton: string
    confirmButton: string
    content: string
    onConfirm?: (event?: SyntheticEvent, data?: object) => void
    onCancel?: (event?: SyntheticEvent, data?: object) => void
}

export const initial_confirm: ConfirmAction = {cancelButton: "Cancel", confirmButton: "Proceed", content: "", open: false}

export function WebCamView(params: { setImage: (image: string) => void }) {
    const parentRef = React.useRef<HTMLDivElement | null>(null)
    const [dimens, setDimens] = React.useState({width: 0, height: 0})

    const webcamRef = React.useRef<Webcam | null>(null);
    const [imgSrc, setImgSrc] = React.useState<string | null>(null);

    const capture = React.useCallback(() => {
        const imageSrc = (webcamRef.current as Webcam).getScreenshot({width: 800, height: 600})
        if (imageSrc !== null) {
            setImgSrc(imageSrc)
            params.setImage(imageSrc as string)
        }
    }, [webcamRef, setImgSrc])

    const [deviceId, setDeviceId] = React.useState<number>(-1);
    const [devices, setDevices] = React.useState<Array<any>>([])

    const handleDevices = React.useCallback((mediaDevices: any) => {
        setDevices(mediaDevices.filter(({kind}: any) => kind === "videoinput"));
    }, [setDevices])

    React.useEffect(() => {
        setDeviceId(devices.length > 0 ? 0 : -1)
    }, [devices])

    React.useEffect(
        () => {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
        }, [handleDevices])

    React.useEffect(() => {
        const width = (parentRef.current as HTMLDivElement).offsetWidth - 20
        setDimens({height: width * 0.75, width: width})
    }, []);

    return (
        <div ref={parentRef} style={{padding: '10px'}}>
            {
                deviceId >= 0 &&
                <>
                    <div className="row mx-0 mb-2">
                        <div className="col-7 pl-0 pr-1">
                            <MySelect
                                name="camera" value={deviceId} placeholder="Select a device"
                                options={[
                                    ...devices.map((device: any, index) => {
                                        return ({text: device.label || `Device ${index + 1}`, value: index})
                                    })
                                ]}/>
                        </div>
                        <div className="col-5 pl-1 pr-0">
                            <Button primary size='mini' icon='camera' labelPosition="left" content="Capture" onClick={capture} fluid/>
                        </div>
                    </div>

                    <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" height={dimens.height} width={dimens.width}
                            videoConstraints={{deviceId: devices[deviceId].deviceId}}/>

                    <div className="mt-2">
                        {imgSrc && (<img src={imgSrc} alt="Image Captured" style={{width: `${dimens.width}px`, height: `${dimens.height}px`}}/>)}
                    </div>
                </>
            }
        </div>
    )
}

export async function read_input(event: ChangeEvent<HTMLInputElement>) {
    return new Promise<InputFile>((resolve, reject) => {
        const files = event.target.files;
        if (files !== null && files.length > 0) {
            const file: File | null = files.item(0);
            const reader = new FileReader();
            reader.readAsDataURL(file as File);
            reader.onload = function (event) {
                const target = event.target;
                if (target !== null) {
                    resolve(new InputFile(target.result as string, file as File))
                } else {
                    reject(null)
                }
            };
            reader.onerror = error => reject(error);
        } else {
            reject(null)
        }
    });
}

export function PaymentMethods(params: { payment: PaymentMethod, setPayment(paymentMethod: PaymentMethod): void, payments: PaymentType[] }) {
    return (
        <div className='payment_methods'>
            {
                params.payments.filter((payment) => payment.payment_type === 'cash').length === 0 &&
                <div onClick={() => params.setPayment({...params.payment, show: true, payment_type: 'cash'})}>
                    <img src={cash_icon} alt=''/>
                </div>
            }

            {
                params.payments.filter((payment) => payment.payment_type === 'mtn').length === 0 &&
                <div onClick={() => params.setPayment({...params.payment, show: true, payment_type: 'mtn'})}>
                    <img src={mtn_mm} alt=''/>
                </div>
            }

            {
                params.payments.filter((payment) => payment.payment_type === 'airtel').length === 0 &&
                <div onClick={() => params.setPayment({...params.payment, show: true, payment_type: 'airtel'})}>
                    <img src={airtel_mm} alt=''/>
                </div>
            }

            {
                Request.COMPANY === "MEDSAFE" &&
                <>
                    {
                        params.payments.filter((payment) => payment.payment_type === 'booking').length === 0 &&
                        <div onClick={() => params.setPayment({...params.payment, show: true, payment_type: 'booking'})}>
                            <img src={booking} alt=''/>
                        </div>
                    }
                </>
            }

            {
                Request.COMPANY === "MEDIPAL" &&
                <>
                    {
                        params.payments.filter((payment) => payment.payment_type === 'pesapal').length === 0 &&
                        <div className="pesapal_button"
                             onClick={() => params.setPayment({...params.payment, show: true, payment_type: 'pesapal'})}>
                            <img src={pesa} alt=''/>
                        </div>
                    }

                    {
                        params.payments.filter((payment) => payment.payment_type === 'dtb').length === 0 &&
                        <div onClick={() => params.setPayment({...params.payment, show: true, payment_type: 'dtb'})}>
                            <img src={dtb} alt=''/>
                        </div>
                    }
                </>
            }
            {
                Request.COMPANY !== "MEDIPAL" &&
                <>
                    {
                        params.payments.filter((payment) => payment.payment_type === 'debit').length === 0 &&
                        <div onClick={() => params.setPayment({...params.payment, show: true, payment_type: 'debit'})}>
                            <img src={debit} alt=''/>
                        </div>
                    }

                    {
                        params.payments.filter((payment) => payment.payment_type === 'credit').length === 0 &&
                        <div onClick={() => params.setPayment({...params.payment, show: true, payment_type: 'credit'})}>
                            <img src={credit} alt=''/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default class Utils {
    static today = (initial?: Date) => {
        if (initial === undefined) {
            initial = new Date()
        }
        const year = initial.getFullYear()
        let month = initial.getMonth() + 1
        const date = initial.getDate()
        return year + "-" + (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date)
    }

    static has_center_role(params: { roles: any, role: string }) {
        return params.roles.hasOwnProperty(params.role) && params.roles[params.role] === 1
    }

    static supports_fcm() {
        return firebase.messaging.isSupported()
    }

    static get_logo() {
        if (Request.COMPANY === "PHARMA") {
            return pharma_logo
        } else if (Request.COMPANY === "MEDIPAL") {
            return medipal_logo
        } else if (Request.COMPANY === "MEDSAFE") {
            return medsafe_logo
        } else if (Request.COMPANY === "MIDDLE EAST") {
            return melabs_logo
        }else if (Request.COMPANY === "TIMES LAB") {
            return times_logo
        }
        return ""
    }

    static post_params(data: any) {
        const form = new FormData();
        Object.entries(data).forEach(([prop, val]) => {
            if (val !== null) {
                if (val instanceof InputFile) {
                    if (val.file !== undefined) {
                        form.append(prop, val.file as File)
                    }
                } else {
                    form.append(prop, val as string)
                }
            }
        })
        return form
    }

    public static in_valid_email(email: string): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !regex.test(email);
    }

    public static invalid_name(name: string): boolean {
        //const regex = /^[a-zA-Z]+(?:-[a-zA-Z]+)*$/;
        const regex = /^([a-zA-Z ]{2,100})$/;
        return !regex.test(name);
    }

    public static invalid_user_name(name: string): boolean {
        const regex = /^[a-zA-Z]{2,30}$/;
        return !regex.test(name);
    }

    public static format_status_name(name: string): string {
        return name.charAt(0).toUpperCase() + name.slice(1)
    }

    public static invalid_text(name: string): boolean {
        //const regex = /^[a-zA-Z0-9 ]{2,500}$/;
        //return !regex.test(name);
        return name.length < 5
    }

    public static invalid_contact(contact: string): boolean {
        const formatted = contact.toString().replace(/\D/g, '').toString()
        if (formatted.indexOf("256") === 0 && formatted.length !== 12) {
            return true
        }
        return formatted.length < 6
    }

    public static localise_date(string: string): string {
        if (string === "") {
            return ""
        }
        const date = new Date(string.replace(/-/g, "/"))
        return date.toLocaleDateString(undefined,
            {weekday: 'short', year: 'numeric', month: 'short', day: '2-digit'})
    }

    public static is_valid_number(value: string | number): boolean {
        return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
    }

    public static comma_number(value: number | string, pre?: string, post?: string): string {
        const prefix = pre === undefined ? 'shs ' : pre
        const postfix = post === undefined ? '' : post
        return prefix + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + postfix
    }

    static row_number(index: number, fetch?: Fetch) {
        let number = (index + 1)
        if (fetch !== undefined) {
            number = (index + 1) + (fetch.limit * (fetch.page - 1))
        }
        /*if (index === 0) {
            return <Label ribbon> {number} </Label>
        } else {
            return number
        }*/
        return number
    }

    static handle_sort = (clickedColumn: string, state: Sortable): Sortable => {
        const {column, data, direction} = state

        if (column !== clickedColumn) {
            return {column: clickedColumn, data: _.sortBy(data, [clickedColumn]), direction: 'ascending'}
        }
        return {...state, data: data.reverse(), direction: direction === 'ascending' ? 'descending' : 'ascending'}
    }

    static get_method = (method: string) => {
        switch (method) {
            case "airtel":
                return 'Airtel Money'
            case "cash":
                return 'Cash'
            case "debit":
                return "Debit Card";
            case "credit":
                return "Credit Card";
            case "mtn":
                return 'MTN Money'
            case 'company':
                return "Company Payment";
            case 'dtb':
                return "Diamond Trust Bank";
            case 'pesapal':
                return "PesaPal";
        }
        return method;
    }

    static generate_csv = (rows: Array<any>) => {
        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", ((new Date()).getTime()) + ".csv");
        document.body.appendChild(link);
        link.click();
    }
}
