import React from "react";
import Users from "./app/management/Users";
import axios from 'axios'
import Utils, {InputFile, Loading, MessageProps, MyMessage, SelectData} from "../utils/Utils";
import PaymentReport from "./app/payments/PaymentReport";
import TestReport from "./app/diagnosis/pcr/TestReport";
import Groups from "./app/company/Groups";
import Login from "./account/Login";
import {Request} from "../utils/Request";
import TestCenters, {initial_center, TestCenter} from "./app/management/TestCenters";
import CompanyReport from "./app/company/CompanyReport";
import Clients from "./app/clients/Clients";
import {Button} from "semantic-ui-react";
import firebase from "firebase/compat";
import LabAntibodyAntigen from "./app/diagnosis/antigen/LabAntibodyAntigen";
import PCRTests from "./app/diagnosis/pcr/PCRTests";
import DashBoard from "./DashBoard";

export interface SystemUser {
    roles: string[]
    userID: number
    userName: string
    user_id: number
    center: TestCenter
}

const initial_user: SystemUser = {roles: [], userID: 0, userName: "", user_id: 0, center: initial_center}

export default function AppBar() {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [login, setLogin] = React.useState({show: false, message: ""})
    const [counter, setCounter] = React.useState(0)

    const [loginToken, setLoginToken] = React.useState("0")
    const [systemUser, setSystemUser] = React.useState<SystemUser>(initial_user)

    const [page, setPage] = React.useState({page: <></>, name: "", title: ""})
    const [testCenters, setTestCenters] = React.useState<Array<TestCenter>>([])
    const [centers, setCenters] = React.useState<Array<SelectData>>([])

    const [menuOpen, setMenuOpen] = React.useState('')

    const update_user_data = (data: any) => {
        const roles: string[] = data.user_roles

        if (!roles.includes("login")) {
            setLogin({
                message: "Your login was suspended, you cannot use this system. Please contact the admin",
                show: true
            })
        } else {
            if (data.user_id !== systemUser.user_id) {
                setSystemUser({
                    roles: roles, userName: `${data.first_name} ${data.last_name}`,
                    userID: (roles.includes("payment_reports")) ? 0 : data.user_id,
                    user_id: data.user_id,
                    center: data.center
                })
                document.title = (data.center as TestCenter).center_name

                let center_data: Array<TestCenter> = [];
                (data.centers as Array<any>).forEach((datum) => {
                    center_data = [...center_data, {...datum, center_logo: new InputFile(`${Request.BASE_URL}${datum.center_logo}`)}]
                })
                setTestCenters(center_data)
            }
            setLoginToken(data.login_token)

            let fcm_token = localStorage.getItem("fcm_token")
            if (fcm_token !== null) {
                Request.update_fcm_token({fcm_token: fcm_token})
            }
        }
    }

    const set_axios_data = () => {
        axios.defaults.baseURL = Request.BASE_URL;
        axios.defaults.headers['common']['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.defaults.timeout = 1000 * 60

        axios.interceptors.response.use(function (response) {
            console.log(response.data)
            console.log(response)

            if (response.data.hasOwnProperty("code") && response.data.code === 401) {
                setLogin({show: true, message: "Account was logged in from another session, your login is required"})
            }
            if (response.data.hasOwnProperty("data") && response.data.data.hasOwnProperty("login_token")) {
                setLoginToken(response.data.data.login_token)
            }
            return response;
        }, function (error) {
            console.log(error)
            return Promise.reject(error);
        });
    }

    window.onclick = () => {
        setCounter(0)
    }

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            if (!login.show) {
                setCounter(counter + 5)
            }

            if (counter >= 600) {
                setLoginToken("")
                setLogin({message: "", show: true})
            }
        }, 5000);
        return () => clearInterval(intervalId);
    }, [counter])

    React.useEffect(() => {
        axios.defaults.headers['common']['Authorization'] = "Bearer " + loginToken
    }, [loginToken])


    React.useEffect(() => {
        if (systemUser.roles.includes("manage_users")) {
            setPage({page: <Users user={systemUser} centers={centers}/>, name: "users", title: "User Management"})

        } else if ((systemUser.roles.includes("manage_groups")) || (systemUser.roles.includes("manage_prices"))) {
            setPage({page: <Groups systemUser={systemUser}/>, name: "groups", title: "Company Management"})

        } else if (systemUser.roles.includes("save_client_data") || systemUser.roles.includes("create_tests")) {
            setPage({
                page: <Clients
                    centers={centers} systemUser={systemUser} user_token={loginToken}
                    companies={
                        (Utils.has_center_role({role: "manage_centers", roles: systemUser.center.center_roles})) &&
                        systemUser.roles.includes("manage_centers")
                    }/>,
                name: "account", title: "Create Test"
            })

        } else if (systemUser.roles.includes("receive_samples") || systemUser.roles.includes("update_tests") ||
            systemUser.roles.includes("verify_results") || systemUser.roles.includes("rds_sync")) {
            setPage({page: <PCRTests centers={centers} systemUser={systemUser}/>, name: "diagnosis", title: "PCR Diagnosis Tests"})

        } else if (systemUser.roles.includes("receive_igg_igm") || systemUser.roles.includes("update_igg_igm") ||
            systemUser.roles.includes("verify_igg_igm")) {
            setPage({page: <LabAntibodyAntigen systemUser={systemUser} centers={centers}/>, name: "tests", title: "Lab SARS Covid"})

        } else if (systemUser.roles.includes("test_reports")) {
            setPage({page: <TestReport centers={centers} systemUser={systemUser}/>, name: "test_reports", title: "Test Results"})

        } else if (systemUser.roles.includes("payment_reports") || systemUser.roles.includes("create_tests")) {

        } else {
            setPage({page: <></>, name: "", title: ""})
        }
    }, [systemUser.roles])

    React.useEffect(() => {
        let _centers: Array<SelectData> = []
        testCenters.forEach((_center) => {
            _centers = [..._centers, {text: _center.center_name, value: _center.center_id}]
        })
        setCenters(_centers)
    }, [testCenters])

    React.useEffect(() => {
        if (loginToken === "0") {
            setLogin({message: "", show: true})
        }
        set_axios_data()
    }, [])

    React.useEffect(() => {
        if (page.name !== "") {
            setMenuOpen("")
        }
    }, [page.name])

    /*FCM SECTION*/
    const send_token_to_server = (web_token: string) => {
        localStorage.setItem("fcm_token", web_token)
        if (loginToken === "0" && localStorage.getItem("fcm_token") !== web_token) {
            Request.update_fcm_token({fcm_token: web_token})
        }
    }

    React.useEffect(() => {
        if (Utils.supports_fcm()) {
            const messaging = firebase.messaging()
            const get_web_token = () => {
                if (Notification.permission === 'granted') {
                    messaging.getToken()
                        .then((currentToken) => {
                            send_token_to_server(currentToken)
                        })
                        .catch((err) => {
                            console.error(err)
                        });
                }
            }

            messaging.onMessage(function (payload) {
                const data = payload.data;
                console.log(data)
                if (data.type === 'received' || data.type === 'bounced') {
                    setMessage({
                        active: true, message: data.body, type: data.type === 'received' ? 'success' : 'error'
                    })
                }
            });

            if ("serviceWorker" in navigator) {
                messaging
                    .getToken({vapidKey: 'BOvt3ZgWBvuioz9HigugjosiYZWEyCeU7qKrwyLzarmyW4nU_J-y-8nT6Y0WTk6bPRLbspZdYRYnsRqBF2xshak'})
                    .then((currentToken) => {
                        if (currentToken) {
                            send_token_to_server(currentToken)
                        } else {
                            console.log('No registration token available. Request permission to generate one.');
                        }
                    })
                    .catch((err) => {
                        console.log('An error occurred while retrieving token. ', err);
                    });
            }

            document.onclick = function () {
                if (Notification.permission !== 'granted') {
                    Notification.requestPermission()
                        .then((permission) => {
                            if (permission === 'granted') {
                                get_web_token();
                            }
                        });
                }
            };
        }
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Login show={login.show} message={login.message}
                   close={(token, userData) => {
                       update_user_data(userData)
                       setLogin({message: "", show: false})
                       setCounter(-5)
                   }}/>

            <div className={`app_sidebar ${menuOpen}`} id="sidebar">
                <div className="app_nav_bar">
                    <img alt="" src={Utils.get_logo()}/>
                    <div className="user">{systemUser.userName}</div>
                </div>

                {
                    Request.COMPANY === "PHARMA" &&
                    <>
                        <span className={`header ${page.name === "dashboard" ? 'active' : ''}`}
                              onClick={() => setPage({page: <DashBoard/>, name: "dashboard", title: "Dashboard"})}>
                            <i className="fas fa-home"/>
                            <span className="sub_title">Home</span>
                        </span>
                    </>
                }

                {
                    (Utils.has_center_role({role: "manage_centers", roles: systemUser.center.center_roles})) &&
                    systemUser.roles.includes("manage_centers") &&
                    <>
                        <span className={`header ${page.name === "test_centers" ? 'active' : ''}`}
                              onClick={() => setPage({
                                  page: <TestCenters
                                      systemUser={systemUser} centers={testCenters}
                                      setCenters={(centers) => setTestCenters(centers)}/>,
                                  name: "test_centers", title: "Test Centers"
                              })}>
                            <i className="fas fa-users-cog"/>
                            <span className="sub_title">Centers' Management</span>
                        </span>
                    </>
                }

                {
                    systemUser.roles.includes("manage_users") &&
                    <>
                        <span
                            onClick={() =>
                                setPage({page: <Users user={systemUser} centers={centers}/>, name: "users", title: "User Management"})}
                            className={`header ${page.name === "users" ? 'active' : ''}`}>
                            <i className="fas fa-users-cog"/>
                            <span className="sub_title">User Management</span>
                        </span>
                    </>
                }

                {
                    (Utils.has_center_role({role: "manage_companies", roles: systemUser.center.center_roles}) &&
                        (systemUser.roles.includes("manage_groups"))) &&
                    <span className={`header ${page.name === "groups" ? 'active' : ''}`}
                          onClick={() => setPage({page: <Groups systemUser={systemUser}/>, name: "groups", title: "Group Management"})}>
                        <i className="fas fa-users"/>
                        <span className="sub_title">Groups &amp; Companies</span>
                    </span>
                }

                {
                    (systemUser.roles.includes("save_client_data") || systemUser.roles.includes("create_tests")) &&
                    <span
                        className={`header ${page.name === "clients" ? 'active' : ''}`}
                        onClick={() => {
                            setPage({
                                page: <Clients
                                    centers={centers} systemUser={systemUser} user_token={loginToken}
                                    companies={
                                        (Utils.has_center_role({role: "manage_centers", roles: systemUser.center.center_roles})) &&
                                        systemUser.roles.includes("manage_centers")
                                    }/>,
                                name: "clients",
                                title: "Client Information"
                            })
                        }}>
                            <i className="fas fa-user"/>
                            <span className="sub_title">Client Information</span>
                        </span>
                }

                {
                    (systemUser.roles.includes("receive_igg_igm") || systemUser.roles.includes("update_igg_igm")
                        || systemUser.roles.includes("verify_igg_igm")) &&
                    <span className={`header ${page.name === "lab_igg_igm" ? 'active' : ''}`}
                          onClick={() => setPage({
                              page: <LabAntibodyAntigen systemUser={systemUser} centers={centers}/>,
                              name: "lab_igg_igm", title: "Lab IGG/IGM"
                          })}>
                            <i className="fas fa-list-alt"/>
                            <span className="sub_title">Lab IGG/IGM</span>
                        </span>

                }

                {
                    (systemUser.roles.includes("receive_samples") || systemUser.roles.includes("update_tests") ||
                        systemUser.roles.includes("verify_results") || systemUser.roles.includes("rds_sync")) &&
                    <span className={`header ${page.name === "diagnosis" ? 'active' : ''}`}
                          onClick={() => {
                              setPage({
                                  page: <PCRTests centers={centers} systemUser={systemUser}/>,
                                  name: "diagnosis", title: "PCR Diagnosis Tests"
                              });
                          }}>
                            <i className="fas fa-list-alt"/>
                            <span className="sub_title">PCR Diagnosis Tests</span>
                        </span>
                }

                {
                    systemUser.roles.includes("test_reports") &&
                    <>
                        <span className={`header ${page.name === "test_reports" ? 'active' : ''}`}
                              onClick={() => {
                                  setPage({
                                      page: <TestReport centers={centers} systemUser={systemUser}/>,
                                      name: "test_reports", title: "Test Reports"
                                  });
                              }}>
                            <i className="fas fa-list-alt"/>
                            <span className="sub_title">Test Reports</span>
                        </span>
                    </>
                }

                {
                    (systemUser.roles.includes("payment_reports") || systemUser.roles.includes("make_payment")) &&
                    <>
                        <span
                            onClick={() => {
                                setPage({
                                    page: <PaymentReport centers={centers} systemUser={systemUser} user_token={loginToken}/>,
                                    name: "payments", title: "Payment Reports"
                                });
                            }}
                            className={`header ${page.name === "payments" ? 'active' : ''}`}>
                            <i className="fas fa-file-pdf"/>
                            <span className="sub_title">Payment Reports</span>
                        </span>
                    </>
                }

                {
                    (Utils.has_center_role({role: "manage_companies", roles: systemUser.center.center_roles})) &&
                    (systemUser.roles.includes("payment_reports")) &&
                    <>
                        <span
                            onClick={() => {
                                setPage({
                                    page: <CompanyReport centers={centers} systemUser={systemUser}/>,
                                    name: "company_report", title: "Company Reports"
                                });
                            }}
                            className={`header ${page.name === "company_report" ? 'active' : ''}`}>
                            <i className="fas fa-file-pdf"/>
                            <span className="sub_title">Company Payments</span>
                        </span>
                    </>
                }

                {
                    loginToken !== "" &&
                    <span className="header"
                          onClick={() => {
                              setSystemUser(initial_user)
                              setLoginToken("")
                              setLogin({message: "", show: true})
                          }}>
                        <i className="fas fa-power-off"/>
                        <span className="sub_title">Log Out</span>
                    </span>
                }

            </div>

            <div className={`nav_content ${menuOpen}`}>
                <div className='main_bar d-md-none'>
                    <div className='user_name'>{page.title}</div>
                    <Button size='mini' primary icon='bars' onClick={() => setMenuOpen(menuOpen === '' ? 'open' : '')}/>
                </div>
                <div className={`app_content`}>{page.page}</div>
            </div>
        </>
    )
}
