import React from "react";
import Utils, {InputFile, MyInput, MySelect, read_input} from "../../../utils/Utils";
import {Button, Checkbox, Table} from "semantic-ui-react";
import {SystemUser} from "../../AppBar";
import PhoneInput from "react-phone-input-2";
import {Request} from "../../../utils/Request";
import {ToastsStore} from "react-toasts";

export type center_roles = "manage_companies" | "manage_centers" | "lab_testing"

export type Roles = {
    [key: string]: 0 | 1;
};

export interface RoleNames {
    [key: string]: string
}

export interface TestCenter {
    center_id: number
    center_name: string
    center_logo: InputFile
    center_contact: string
    center_email: string
    box_number: string
    location: string
    test_prefix: string
    center_type: 'testing' | 'booking'
    center_roles: Roles
}

export const initial_center: TestCenter = {
    box_number: "", center_contact: "", center_email: "", center_id: 0, center_logo: new InputFile(""),
    center_name: "", location: "", test_prefix: "", center_type: 'testing', center_roles: {}
}

export default function TestCenters(props: {
    systemUser: SystemUser, centers: Array<TestCenter>, setCenters: (centers: Array<TestCenter>) => void
}) {
    const [loading, setLoading] = React.useState(false)
    const [saving, setSaving] = React.useState(false)

    const roles: RoleNames = {
        "manage_companies": "Manage Companies and Groups",
        "manage_centers": "Manage Test Centers",
        "lab_testing": "Manage Lab Tests"
    }

    const [search, setSearch] = React.useState({center_name: ""})
    const handle_search = (name: string, value: string) => {
        setSearch({...search, [name]: value})
    }

    const [center, setCenter] = React.useState(initial_center)
    const [centers, setCenters] = React.useState<Array<TestCenter>>([])
    const handle_center = (name: string, value: string | InputFile) => {
        setCenter({...center, [name]: value})
    }
    const handle_role = (name: string, checked: boolean) => {
        const roles: Roles = {...center.center_roles, [name]: checked ? 1 : 0}
        setCenter({...center, center_roles: roles})
    }
    const load_centers = () => {
        setLoading(true)
        Request.get_test_center()
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    let center_data: Array<TestCenter> = [];
                    (response.data.data as Array<any>).forEach((datum) => {
                        center_data = [...center_data, {...datum, center_logo: new InputFile(Request.BASE_URL + datum.center_logo)}]
                    })
                    props.setCenters(center_data)
                    setCenters(center_data)
                } else {
                    ToastsStore.error("Error while loading test centers, please retry")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Error while loading test centers, please retry")
            })
    }

    const save_center = () => {
        setSaving(true)
        Request.save_test_center({
            box_number: center.box_number, center_contact: center.center_contact, center_email: center.center_email,
            center_id: center.center_id, center_logo: center.center_logo, center_name: center.center_name,
            center_roles: JSON.stringify(center.center_roles), center_type: center.center_type,
            location: center.location, test_prefix: center.test_prefix
        })
            .then((response) => {
                setSaving(false)
                if (response.data.hasOwnProperty("code")) {
                    switch (response.data.code as number) {
                        case 1:
                            const _new_center = {
                                ...center,
                                center_id: response.data.data.center_id,
                                center_logo: new InputFile(`${Request.BASE_URL}${response.data.data.center_logo}`)
                            };

                            let test_centers: Array<TestCenter> = []
                            if (center.center_id === 0) {
                                test_centers = [_new_center, ...centers]
                            } else {
                                test_centers = centers.map((_center) =>
                                    _center.center_id === center.center_id ? _new_center : _center)
                            }

                            setCenter(_new_center)
                            props.setCenters(test_centers)
                            setCenters(test_centers)
                            ToastsStore.success("Saved test center successfully")
                            break
                        case 2:
                            ToastsStore.error("Center name is already saved")
                            break
                        case 3:
                            ToastsStore.error("Test prefix is already used")
                            break
                    }
                } else {
                    ToastsStore.error("Could not save center, please retry")
                }
            })
            .catch(() => {
                setSaving(false)
                ToastsStore.error("Could not save center, please retry")
            })
    }

    React.useEffect(() => {
        console.log("This thing must be reloading now, but why not???????????????")
        setCenters(props.centers)
    }, [props.centers])

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-1">
                        <div className="content_bar">
                            <div className="search_bar">
                                <div style={{width: '250px'}}>
                                    <MyInput placeholder="Search by name" name="client_name" value={search.center_name}
                                             change={handle_search} enter={load_centers}/>
                                </div>
                            </div>

                            <div className="content_buttons">
                                <Button
                                    primary size='mini' loading={loading} disabled={loading}
                                    icon='search' labelPosition="left" content="Search Centers" onClick={load_centers}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-8 p-1">
                        <div className="table_container user_container bg_white p-2">
                            <Table celled striped compact size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '250px'}}>Center Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '150px'}}>Main Contact</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '70px'}}>Prefix</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        centers.map((center, index) =>
                                            <Table.Row key={center.center_id} onClick={() => setCenter({...center})}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '250px'}}>{center.center_name}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{center.center_contact}</Table.Cell>
                                                <Table.Cell style={{width: '70px'}}>{center.test_prefix}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <div className="table_container user_container p-0">
                            <div className="user_data_form bg_white">
                                <div className='mb-2'>
                                    <img src={center.center_logo.file_src} alt='' className='center_logo'/>

                                    <div className="input_container" style={{width: '100%', margin: '0 auto'}}>
                                        <input
                                            type="file" className="input_file" id="center_logo_input"
                                               accept="image/*" multiple={false}
                                               onChange={(event) => {
                                                   read_input(event)
                                                       .then((response) => {
                                                           handle_center('center_logo', response);
                                                           (document.getElementById("center_logo_input") as HTMLInputElement).value = "";
                                                       })
                                                       .catch((error) => {
                                                           console.log(error)
                                                       })
                                               }}/>
                                        <label htmlFor="center_logo_input" className="ui mini primary button fluid">
                                            <i className="ui upload icon"/>Select an image file
                                        </label>
                                    </div>
                                </div>

                                <div className="user_data_container">
                                    <div className="label">Center name</div>
                                    <MyInput placeholder="Enter center name" name="center_name"
                                             value={center.center_name} change={handle_center}/>
                                </div>

                                <div className='row m-0'>
                                    <div className='col-6 pl-0 pr-1'>
                                        <div className="user_data_container">
                                            <div className="label">Center Prefix</div>
                                            <MyInput placeholder="Enter center prefix" name="test_prefix"
                                                     value={center.test_prefix} change={handle_center}/>
                                        </div>
                                    </div>

                                    <div className='col-6 pr-0 pl-1'>
                                        <div className="user_data_container">
                                            <div className="label">Center Type</div>
                                            <MySelect name='center_type' value={center.center_type} placeholder=""
                                                      change={(value) => handle_center('center_type', value as string)}
                                                      options={[
                                                          {text: 'Testing Center', value: 'testing'},
                                                          {text: 'Booking Center', value: 'booking'}
                                                      ]}/>
                                        </div>
                                    </div>
                                </div>


                                <div className="user_data_container">
                                    <div className="label">Center Contact</div>
                                    <PhoneInput
                                        countryCodeEditable={false} country={'ug'} value={`+${center.center_contact}`}
                                        onChange={(value) => handle_center('center_contact', value)}/>
                                </div>

                                <div className="user_data_container">
                                    <div className="label">Center Email</div>
                                    <MyInput placeholder="Enter center email" name="center_email"
                                             value={center.center_email} change={handle_center}/>
                                </div>

                                <div className="user_data_container">
                                    <div className="label">Box Number</div>
                                    <MyInput placeholder="Enter center email" name="box_number"
                                             value={center.box_number} change={handle_center}/>
                                </div>

                                <div className="user_data_container">
                                    <div className="label">Center Location</div>
                                    <MyInput placeholder="Enter center location" name="location"
                                             value={center.location} change={handle_center}/>
                                </div>

                                {
                                    Object
                                        .entries(roles)
                                        .map(([key, value]) =>
                                            <div className="diagnosis_test" key={key}>
                                                <Checkbox
                                                    label={value}
                                                    checked={
                                                        center.center_roles.hasOwnProperty(key) &&
                                                        center.center_roles[key] === 1
                                                    }
                                                    onChange={((event, data) => {
                                                        handle_role(key, data.checked as boolean)
                                                    })}/>
                                            </div>
                                        )
                                }
                            </div>

                            <div className="user_button bg_white">
                                <div className="row m-0">
                                    <div className="col-6 pl-0 pr-1">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                                disabled={saving || loading}
                                                content="Clear Data" onClick={() => setCenter(initial_center)}/>
                                    </div>
                                    <div className="col-6 pl-1 pr-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid loading={saving}
                                                content="Save Center" onClick={save_center} disabled={saving || loading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
