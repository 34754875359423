import {Button, Confirm, Modal, Table} from "semantic-ui-react";
import Utils, {Loading, my_initial_confirm, MyConfirmAction, MyDateInput, MyTimeInput, ShowPDF} from "../../../../../utils/Utils";
import React from "react";
import {Request} from "../../../../../utils/Request";
import {ReportTest, sample_status} from "../PCRTests";
import {SystemUser} from "../../../../AppBar";
import axios from "axios";
import {ToastsStore} from "react-toasts";
import moment from "moment";

interface Log {
    log_details: string
    log_name: sample_status
    log_time: string
    user_name: string
}

interface Test {
    diagnosis_id: number
    diagnosis_no: string
    client_name: string
    results: "Positive" | 'Negative' | 'N/A' | 'Invalid' | 'Inconclusive'
    logs: Log[]
    triage: { date: '', signs: string[], state: string[] }
}

export default function DiagnosisDetails(params: {
    tests: ReportTest[], setTests: (tests: ReportTest[]) => void, diagnosis_id: number, close: () => void, systemUser: SystemUser
}) {
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [showReceipt, setShowReceipt] = React.useState({show: false, url: ""})
    const [test, setTest] = React.useState<Test>({
        diagnosis_id: 0, diagnosis_no: "", results: "N/A", triage: {date: "", signs: [], state: []}, client_name: "", logs: []
    })

    const [showUpdate, setShowUpdate] = React.useState(false)
    const [update, setUpdate] = React.useState({
        created: {time: '', date: ''}, received: {time: '', date: ''},
        loaded: {time: '', date: ''}, results: {time: '', date: ''}
    })

    const get_test_data = (diagnosis_id: number) => {
        setLoader({message: "Loading test data, please wait", show: true})
        Request.get_test_data({diagnosis_id: diagnosis_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setTest(response.data.data)
                } else {
                    params.close()
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                params.close()
            })
    }

    const re_upload_results = () => {
        setConfirm({
            ...confirm,
            open: true, content: 'Are you sure you want to re-dispatch these results',
            confirmButton: "Proceed", cancelButton: "Cancel",
            onCancel: () => setConfirm({...confirm, open: false}),
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                axios.defaults.timeout = 1000 * 60 * 5
                setLoader({message: "Dispatching results to RDS, please wait", show: true})

                Request.synchronise_csv_file({
                    ids: JSON.stringify([params.diagnosis_id]), no_gene: 1,
                    duplicate: test.logs.filter((log) => log.log_name === "uploaded").length
                })
                    .then((response) => {
                        setLoader({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                ToastsStore.success("Successfully synchronised results to RDS")
                                //setResponses(response.data.responses)
                            } else {
                                ToastsStore.error("Could not synchronise results to RDS, please retry")
                            }
                        }
                        axios.defaults.timeout = 1000 * 60
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                        ToastsStore.error("Could not synchronise results to RDS, please retry")
                        axios.defaults.timeout = 1000 * 60
                    })
            }
        })
    }

    const get_certificate = () => {
        setLoader({message: "Loading PDF Certificate, please wait", show: true})

        Request.get_pdf_certificate({diagnosis_no: test.diagnosis_no})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setShowReceipt({show: true, url: `${Request.BASE_URL}${response.data.data.url}`})
                } else {
                    ToastsStore.error("Could not download PDF Certificate, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not download PDF Certificate, please retry")
            })
    }

    const update_info = () => {
        if (update.created.date === '' || update.created.time === '') {
            ToastsStore.error("Enter a valid date and time of creation")
        } else if (update.received.date === '' || update.received.time === '') {
            ToastsStore.error("Enter a valid date and time of reception in lab")
        } else if (update.loaded.date === '' || update.loaded.time === '') {
            ToastsStore.error("Enter a valid date and time of loading in lab")
        } else if (update.results.date === '' || update.results.time === '') {
            ToastsStore.error("Enter a valid date and time of results")
        } else {
            const logs = [
                {log_time: `${update.created.date} ${update.created.time}`, log_name: 'created'},
                {log_time: `${update.received.date} ${update.received.time}`, log_name: 'received'},
                {log_time: `${update.loaded.date} ${update.loaded.time}`, log_name: 'loaded'},
                {log_time: `${update.results.date} ${update.results.time}`, log_name: 'results'},
            ]

            setLoader({message: "Updating logs time, please wait", show: true})
            Request.update_test_time({diagnosis_id: test.diagnosis_id, logs: JSON.stringify(logs)})
                .then((response) => {
                    setLoader({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        ToastsStore.success("Logs time successfully updated")
                        setShowUpdate(false)
                        get_test_data(test.diagnosis_id)
                    } else {
                        ToastsStore.error("Could not update logs time, please retry")
                    }
                })
                .catch(() => {
                    setLoader({message: "", show: false})
                    ToastsStore.error("Could not update logs time, please retry")
                })
        }
    }

    React.useEffect(() => {
        let _update = {
            created: {time: '', date: ''}, received: {time: '', date: ''},
            loaded: {time: '', date: ''}, results: {time: '', date: ''}
        }
        test.logs.forEach((log) => {
            if (log.log_name === "created") {
                _update.created = {time: log.log_time.split(' ')[1], date: log.log_time.split(' ')[0]}
            } else if (log.log_name === "received") {
                _update.received = {time: log.log_time.split(' ')[1], date: log.log_time.split(' ')[0]}
            } else if (log.log_name === "loaded") {
                _update.loaded = {time: log.log_time.split(' ')[1], date: log.log_time.split(' ')[0]}
            } else if (log.log_name === "results") {
                _update.results = {time: log.log_time.split(' ')[1], date: log.log_time.split(' ')[0]}
            }
        })
        setUpdate(_update)
    }, [test.logs])

    React.useEffect(() => {
        if (params.diagnosis_id > 0) {
            get_test_data(params.diagnosis_id)
        }
    }, [params.diagnosis_id])

    return (
        <>
            {/*printing modal*/}
            <ShowPDF show={showReceipt.show} close={() => setShowReceipt({...showReceipt, show: false})} url={showReceipt.url}/>

            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content} centered={false}/>

            <Modal size='mini' open={showUpdate} centered={false}>
                <div className="verify_container">
                    <div className="diagnosis_no">Update Test Time</div>

                    <div className="verify_data">
                        <div className="label">Time Created</div>
                        <div className='row mx-0 mb-2'>
                            <div className='col-7 pl-0 pr-1'>
                                <MyDateInput
                                    value={update.created.date} name={''} placeholder={''}
                                    change={(name, value) => setUpdate({...update, created: {...update.created, date: value}})}/>
                            </div>
                            <div className='col-5 pl-1 pr-0'>
                                <MyTimeInput
                                    value={update.created.time} name={''} placeholder={''}
                                    change={(name, value) => setUpdate({...update, created: {...update.created, time: value}})}/>
                            </div>
                        </div>

                        <div className="label">Time Received</div>
                        <div className='row mx-0 mb-2'>
                            <div className='col-7 pl-0 pr-1'>
                                <MyDateInput
                                    value={update.received.date} name={''} placeholder={''}
                                    change={(name, value) => setUpdate({...update, received: {...update.received, date: value}})}/>
                            </div>
                            <div className='col-5 pl-1 pr-0'>
                                <MyTimeInput
                                    value={update.received.time} name={''} placeholder={''}
                                    change={(name, value) => setUpdate({...update, received: {...update.received, time: value}})}/>
                            </div>
                        </div>

                        <div className="label">Time Loaded</div>
                        <div className='row mx-0 mb-2'>
                            <div className='col-7 pl-0 pr-1'>
                                <MyDateInput
                                    value={update.loaded.date} name={''} placeholder={''}
                                    change={(name, value) => setUpdate({...update, loaded: {...update.loaded, date: value}})}/>
                            </div>
                            <div className='col-5 pl-1 pr-0'>
                                <MyTimeInput
                                    value={update.loaded.time} name={''} placeholder={''}
                                    change={(name, value) => setUpdate({...update, loaded: {...update.loaded, time: value}})}/>
                            </div>
                        </div>

                        <div className="label">Results Time</div>
                        <div className='row mx-0 mb-2'>
                            <div className='col-7 pl-0 pr-1'>
                                <MyDateInput
                                    value={update.results.date} name={''} placeholder={''}
                                    change={(name, value) => setUpdate({...update, results: {...update.results, date: value}})}/>
                            </div>
                            <div className='col-5 pl-1 pr-0'>
                                <MyTimeInput
                                    value={update.results.time} name={''} placeholder={''}
                                    change={(name, value) => setUpdate({...update, results: {...update.results, time: value}})}/>
                            </div>
                        </div>
                    </div>

                    <div className="row verify_button m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button
                                negative icon="close" labelPosition="left" size="mini" fluid
                                content="Close Window" onClick={() => setShowUpdate(false)}/>
                        </div>
                        <div className="col-6 p1-1 pr-0">
                            <Button
                                positive icon="save" labelPosition="left" size="mini" fluid
                                content="Update Data" onClick={update_info}/>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal size='small' open={params.diagnosis_id > 0} centered={false} onClose={params.close}>
                <div className="verify_container">
                    <div className="diagnosis_no">{test.diagnosis_no}</div>

                    <div className="verify_data">
                        <div className="label">Client Name</div>
                        <div className="label_info">{test.client_name}</div>

                        <div className="label">Diagnosis Results</div>
                        <div className="label_info">{test.results}</div>

                        {
                            test.triage.date !== "" &&
                            <>
                                <div className="label">Symptoms Date</div>
                                <div className="label_info">{moment(test.triage.date).format("lll")}</div>
                            </>
                        }

                        {
                            test.triage.signs.length > 0 &&
                            <>
                                <div className="label mt-3">Clinical Results</div>
                            </>
                        }

                        {
                            test.triage.state.length > 0 &&
                            <>
                                <div className="label mt-3">Other Conditions</div>
                            </>
                        }

                        <div className="label mt-3">Diagnosis Logs</div>
                        <div className="table_container">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '90px'}}>Log Type</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '140px'}}>Log Date</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '180px'}}>User Name</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        test.logs.map((log, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '90px'}}>
                                                    {log.log_name.charAt(0).toUpperCase() + log.log_name.slice(1)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{moment(log.log_time).format('llll')}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{log.user_name}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="row verify_button m-0">
                        <div className="col-5 col-md-4 pl-0 pr-1">
                            <Button
                                negative icon="edit" labelPosition="left" size="mini" fluid
                                disabled={
                                    Request.COMPANY !== 'MEDSAFE' ||
                                    test.logs.filter((log) => log.log_name === "results").length === 0
                                }
                                content="Edit Test" onClick={() => setShowUpdate(true)}/>
                        </div>
                        <div className="col-5 col-md-4 p1-0 pr-0">
                            <Button
                                positive icon="file pdf" labelPosition="left" size="mini" fluid
                                disabled={test.logs.filter((log) => log.log_name === "uploaded").length === 0}
                                content="Certificate" onClick={get_certificate}/>
                        </div>
                        <div className="col-2 col-md-4 p1-1 pr-0">
                            <Button
                                positive icon="save" labelPosition="left" size="mini" fluid
                                disabled={
                                    !params.systemUser.roles.includes("rds_sync") ||
                                    test.logs.filter((log) => log.log_name === "uploaded").length === 0
                                }
                                content="Re Upload" onClick={re_upload_results}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
